"use client";

import { Text } from "@/components/ui/text";
import { Form, FormCheckbox } from "@/components/ui/form";
import { FormInput } from "@/components/ui/form";
import { useTranslations } from "next-intl";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link } from "@/components/ui/Link";
import { Icon } from "@/components/ui/Icon/Icon";

export function Newsletter() {
	const t = useTranslations("FooterNewsletter");

	const newsletterForm = z.object({
		newsletterEmail: z.string().email(t("errors.email")),
		acceptPrivacyPolicy: z.boolean().refine((e) => e, { message: t("errors.data-protection") }),
	});

	type NewsletterForm = z.infer<typeof newsletterForm>;

	const form = useForm<NewsletterForm>({
		resolver: zodResolver(newsletterForm),
		defaultValues: {
			newsletterEmail: "",
			acceptPrivacyPolicy: false,
		},
	});

	async function registerNewsletter(values: NewsletterForm) {
		const body = { email: values.newsletterEmail, acceptPrivacyPolicy: values.acceptPrivacyPolicy };
		try {
			const res = await fetch("/server/newsletter/krasserstoff", { method: "POST", body: JSON.stringify(body) });
			if (res.status !== 200) form.setError("root", { message: t("errors.api") });
		} catch (e) {
			form.setError("root", { message: t("errors.api") });
		}
	}

	return (
		<>
			<Text asChild>
				<h4 className="mb-2">{t("title")}</h4>
			</Text>
			<Text asChild>
				<p className="mb-4">{t("text")}</p>
			</Text>
			<Form {...form}>
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						await form.handleSubmit(registerNewsletter)();
					}}
				>
					<div className="mb-2 flex w-full">
						<FormInput
							className="grow"
							name={"newsletterEmail" satisfies keyof NewsletterForm}
							placeholder={t("email")}
							inverted
							inlineButton={{
								label: <Icon icon="arrow-right" className="size-4 text-white" />,
								props: {
									["aria-label"]: t("submit"),
									loading: form.formState.isSubmitting,
									type: "submit",
								},
							}}
						/>
					</div>
					<FormCheckbox name={"acceptPrivacyPolicy" satisfies keyof NewsletterForm}>
						{t.rich("terms", {
							privacyPolicy: (chunks) => (
								<Link
									href="/datapolicy"
									target="_blank"
									underlined
									className={"h-auto p-0"}
									onClick={(e) => e.preventDefault}
								>
									{chunks}
								</Link>
							),
						})}
					</FormCheckbox>
					{form.formState.isSubmitSuccessful && <Text className="mt-3 text-green-dark">{t("success")}</Text>}
					{form.formState.errors.root && (
						<Text className="mt-3 text-red-dark">{form.formState.errors.root.message}</Text>
					)}
				</form>
			</Form>
		</>
	);
}
