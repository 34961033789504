import { useTranslations } from "next-intl";
import { FooterElement } from "./FooterElement";
import { Link } from "@/components/ui/Link";
import { Text } from "@/components/ui/text";
import { Newsletter } from "./Newsletter";
import { LinksLegal } from "./LinksLegal";
import { KSLogo } from "../KSLogo";
import { cn } from "@/lib/cn";
import { Frame } from "@/components/layout/Frame";
import { Icon } from "@/components/ui/Icon/Icon";

type Props = {
	variant?: "heavy" | "medium" | "light";
	className?: string;
};

export const linkClasses = "flex h-full items-center";

/**
 * Creates the Footer for the KS Shop
 * @param variant heavy = with scribble + logo, medium = with logo, light = only links and newsletter
 */
export function Footer({ variant = "medium", className }: Props) {
	const t = useTranslations("Footer");

	return (
		<footer
			role="contentinfo"
			className={`bg-ks-scribble bg-ks-scribble-size bg-fixed bg-repeat ${
				(cn(variant !== "heavy" && "bg-none"), className)
			}`}
		>
			<Frame className="bg-white pb-4 pt-8 sm:py-8">
				<div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
					<FooterElement>
						<div className="flex justify-between">
							<Link href={""} locale={"de"} className={linkClasses} underlineOnHover>
								<Text>{t("german")}</Text>
							</Link>

							<Link href={""} locale={"en"} underlineOnHover className={linkClasses}>
								<Text>{t("english")}</Text>
							</Link>
						</div>
						<div className="flex justify-between">
							<Link shopSlug="krasserstoff" href={`/account`} underlineOnHover className={linkClasses}>
								<Text>{t("my-online-tickets")}</Text>
							</Link>
							<Link href={`/faq`} underlineOnHover className={linkClasses}>
								<Text>{t("help")}</Text>
							</Link>
						</div>
						<div className="flex justify-between">
							<Link href={`https://instagram.com/krasserstoff/`} underlineOnHover className={linkClasses}>
								<Text>{t("instagram")}</Text>
							</Link>
							<Link href={`https://facebook.com/krasserstoff`} underlineOnHover className={linkClasses}>
								<Text>{t("facebook")}</Text>
							</Link>
						</div>
						<div className="hidden justify-between lg:flex">
							<LinksLegal />
						</div>
						<div className="lg:hidden">
							<Newsletter />
						</div>
					</FooterElement>
					<FooterElement hiddenMobile>
						<Link
							href={"/faq#" + t("faqLinks.1")}
							className="flex items-center justify-between"
							underlineOnHover
						>
							<Text>{t("faq-shortcuts.online-tickets")}</Text>
							<Icon icon="arrow-right" className="ml-2 size-4" />
						</Link>
						<Link
							href={"/faq#" + t("faqLinks.2")}
							className="flex items-center justify-between"
							underlineOnHover
						>
							<Text>{t("faq-shortcuts.delivery-costs")}</Text>
							<Icon icon="arrow-right" className="ml-2 size-4" />
						</Link>
						<Link
							href={"/faq#" + t("faqLinks.3")}
							className="flex items-center justify-between"
							underlineOnHover
						>
							<Text>{t("faq-shortcuts.delivery-time")}</Text>
							<Icon icon="arrow-right" className="ml-2 size-4" />
						</Link>
						<Link
							href={"/faq#" + t("faqLinks.4")}
							className="flex items-center justify-between"
							underlineOnHover
						>
							<Text>{t("faq-shortcuts.payment-methods")}</Text>
							<Icon icon="arrow-right" className="ml-2 size-4" />
						</Link>
					</FooterElement>
					<FooterElement hiddenMobile>
						<div>
							<Newsletter />
						</div>
					</FooterElement>
				</div>
				{variant !== "light" && (
					<>
						<div className={`mb-8 mt-10 hidden w-full lg:block`}>
							<KSLogo variant="oneLine" />
						</div>
						<div className={`mb-2 mt-15 w-full lg:hidden`}>
							<KSLogo variant="twoLines" />
						</div>
					</>
				)}
				<div className="mt-2 flex justify-between lg:hidden">
					<LinksLegal />
				</div>
			</Frame>
			{variant === "heavy" && (
				<>
					<div className="h-96 w-full">
						<div className="h-6 w-full bg-gradient-to-b from-black opacity-20" />
					</div>
				</>
			)}
		</footer>
	);
}
