"use client";

import { Footer } from "@/components/content/Footer/Footer";
import * as Sentry from "@sentry/nextjs";
import Error from "next/error";
import { useEffect } from "react";

export default function GlobalError({
	error,
	params: { locale },
}: {
	error: Error & { digest?: string };
	params: { locale: string };
}) {
	useEffect(() => {
		Sentry.captureException(error);
	}, [error]);

	return (
		<html lang={locale}>
			<body>
				<Error statusCode={error.props.statusCode} />
				<Footer variant="heavy" />
			</body>
		</html>
	);
}
