import { twx } from "@/lib/cn";

/**
 * The outermost container for pages, set the max-width of the page to 1600px (i.e. 3xl)
 */
export const ViewportBoundary = twx.div`max-w-screen-3xl m-auto w-full`;

/**
 * Frame for all outer components, streamlines breakpoints for outside padding. If there is a container query present, the breakpoints are oriented via the container query. This applies to the `bandshop-home` layout.
 */
export const Frame = twx.div`px-2 sm:px-7 sm:@[0px]/page:px-2 sm:@xl/page:px-7`;
