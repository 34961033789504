import { cn } from "@/lib/cn";
import React from "react";

type Props = {
	hiddenMobile?: boolean;
	children: React.ReactNode;
	className?: string;
};

export function FooterElement({ children, hiddenMobile, className }: Props) {
	return (
		<div
			className={cn(
				"flex size-full flex-col",
				"[&>*:not(:first-child)]:grow [&>*:not(:first-child)]:border-b [&>*:not(:first-child)]:border-black [&>*:not(:first-child)]:py-4",
				hiddenMobile && "hidden lg:flex",
				className
			)}
		>
			<div className="h-3 w-full grow-0 bg-black" />
			{children}
		</div>
	);
}
