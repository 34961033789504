import { useTranslations } from "next-intl";
import { Link } from "@/components/ui/Link";
import { Text } from "@/components/ui/text";
import { linkClasses } from "./Footer";

export function LinksLegal() {
	const t = useTranslations("Footer");

	return (
		<>
			<Link href={`/contact`} underlineOnHover>
				<Text className={linkClasses}>{t("imprint")}</Text>
			</Link>
			<Link href={`/terms`} underlineOnHover>
				<Text className={linkClasses}>{t("gtc")}</Text>
			</Link>
			<Link href={`/datapolicy`} underlineOnHover>
				<Text className={linkClasses}>{t("privacy-policy")}</Text>
			</Link>
			<Link shopSlug="krasserstoff" target="_blank" href={`/jobs`} underlineOnHover>
				<Text className={linkClasses}>{t("jobs")}</Text>
			</Link>
		</>
	);
}
